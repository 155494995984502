import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Home</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/philosophy">Our Philosophy</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/education">Education</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/priorworks">Prior Works</Link></li>
            </ul>
            <ul className="actions vertical">
                <li><a href="#contact" className="button special fit">Contact Us</a></li>
                <li><a href="https://cryptotech.guru/clientzone" className="button fit">Client Zone</a></li>
            </ul>
        </div>
        <Link className="close" onClick={props.onToggleMenu} to="/">Close</Link>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
