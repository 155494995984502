import React from 'react'

const Contact = (props) => (
    <section id="contact" style={{backgroundColor: "#9966FF"}}>
        <div className="inner">
            <section>
              <h1>Contact us!</h1>
            </section>
            <section className="split">
                <section><br></br>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <p><b>Email: </b>info@cryptotech.guru</p>
                    </div>
                </section>
            </section>
        </div>
    </section>
) 

export default Contact
