import React from 'react'
import canary from '../assets/images/canary.png'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
                <li><a href="https://github.com/cryptotechguru/Projects/wiki" className="icon alt fa-github"><span className="label">cryptotechguru</span></a></li>
                <li><a href="https://keybase.io/csaucier" className="icon alt fa-key"><span className="label">csaucier</span></a></li>
                <li><a href="https://www.linkedin.com/in/csaucier/" className="icon alt fa-linkedin"><span className="label">/in/csaucier</span></a></li>
            </ul><img src={canary} alt="" align="right"/>
            <ul className="copyright">
                <li>Cryptotech.guru 2019</li><li>Design: <a href="https://html5up.net">HTML5 UP</a></li><li></li>
            </ul>
        </div>
    </footer>
)

export default Footer
